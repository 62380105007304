import {
  ADD_ROLE,
  EDIT_ROLE,
  GET_SUB_ROLE,
  GET_SUB_USER_PERMISSION,
  RESET_DEALER_SUB_USER,
  EDIT_SUB_USER,
  GET_DEALER_SUB_ROLE,
  ADD_SUB_DEALER_USER,
  EDIT_SUB_DEALER_USER,
} from "../types";

const initialState = {
  roleList: [],
  permissionList: [],
  dealerSubUser: {
    dealerSubUserList: [],
    total: 0,
  },
  total: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_ROLE:
      return {
        ...state,
        roleList: [payload, ...state.roleList],
      };

    case EDIT_SUB_USER:
      console.log(payload);

      const updatedRoleList = state.roleList.map((role) => {
        if (role.id === payload.id) {
          return {
            ...role,
            is_active: payload?.is_active,
          };
        }
        return role;
      });

      return {
        ...state,
        roleList: updatedRoleList,
      };

    case EDIT_SUB_DEALER_USER:
      console.log(payload);

      const updateSubUserList = state?.dealerSubUser?.dealerSubUserList?.map(
        (role) => {
          if (role.id === payload.id) {
            return {
              ...role,
              ...payload,
            };
          }
          return role;
        }
      );

      return {
        ...state,
        roleList: updatedRoleList,
      };

    case GET_SUB_ROLE:
      return {
        ...state,
        roleList: payload,
        total: payload?.length,
      };

    case GET_DEALER_SUB_ROLE:
      return {
        ...state,
        dealerSubUser: { dealerSubUserList: payload, total: payload?.length },
      };
    case GET_SUB_USER_PERMISSION:
      return {
        ...state,
        permissionList: payload,
      };
    case RESET_DEALER_SUB_USER:
      return {
        ...state,
        permissionList: [],
      };
    default:
      return state;
  }
}
