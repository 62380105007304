import { useSelector } from "react-redux";
import { AUTH_ROUTES, getAbsolute, MAIN_ROUTES } from "./routes";
import { Header } from "../components/header";

const Headings = [
  {
    route: AUTH_ROUTES.SIGNIN,
    title: ["SignIn"],
  },
  {
    route: AUTH_ROUTES.RESET_PASS,
    title: ["Reset Password"],
  },
  {
    route: MAIN_ROUTES.DASHBOARD,
    title: ["Dashboard"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.DEALER_MANAGEMENT.DEALERS),
    title: ["Dealers", "Dealer List"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.DEALER_MANAGEMENT.DEALER_PREFERENCE),
    title: ["Dealers", "Dealer List", "Dealer Preference"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.DEALER_MANAGEMENT.NEW_DEALER),
    title: ["Dealers", "Dealer List", "Add New Dealer"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.DEALER_MANAGEMENT.EDIT_DEALER),
    title: ["Dealers", "Dealer List", "Edit Dealer"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.DEALER_MANAGEMENT.VIEW_DEALER),
    title: ["Dealers", "Dealer List", "View Dealer"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.DEALER_MANAGEMENT.DOCUMENT_UPLOAD),
    title: ["Dealers", "Dealer List", "Documents Upload"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.DEALER_MANAGEMENT.UPLOAD_DEALER),
    title: ["Dealers", "Dealer List", "View Uploaded Documents"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.DEALER_MANAGEMENT.WEBSITE_HEADING),
    title: ["Dealers", "Dealer List", "Website Heading"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.DEALER_MANAGEMENT.DEALERSUBUSER),
    title: ["Dealers", "Sub User List"],
  },

  {
    route: getAbsolute(MAIN_ROUTES.DEALER_MANAGEMENT.NEW_DEALERSUBUSER),
    title: ["Dealers", "Sub User List", "New Sub User"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.DEALER_MANAGEMENT.EDIT_DEALERSUBUSER),
    title: ["Dealers", "Sub User List", "Edit Sub User"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.DEALER_MANAGEMENT.VIEW_DEALERSUBUSER),
    title: ["Dealers", "Sub User List", "View Sub User"],
  },
  {
    route: MAIN_ROUTES.USER_AND_PERMISSION,
    title: ["User and Permissions"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.MASTER_MANAGEMENT.USERS),
    title: ["Master Management", "Users"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.MASTER_MANAGEMENT.NEW_USER),
    title: ["Master Management", "Users", "Add New User"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.MASTER_MANAGEMENT.VIEW_USER),
    title: ["Master Management", "Users", "View user"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.MASTER_MANAGEMENT.ROLES),
    title: ["Master Management", "Roles"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.MASTER_MANAGEMENT.NEW_ROLE),
    title: ["Master Management", "Roles", "Add New Role"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.MASTER_MANAGEMENT.EDIT_ROLE),
    title: ["Master Management", "Roles", "Edit Role"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.MASTER_MANAGEMENT.VIEW_ROLE),
    title: ["Master Management", "Roles", "View Role"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.PICKUP_REQUEST),
    title: ["RTO Services", "Door Services"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.ADD_DOOR_SERVICE),
    title: ["RTO Services", "Door Services", "Add Door Service"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.EDIT_DOOR_SERVICE),
    title: ["Services", "Door Service", "Edit Door Service"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.VIEW_DOOR_SERVICE),
    title: ["Services", "Door Service", "View Door Service"],
  },

  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.RTO_CASES),
    title: ["Services", "RTO Cases"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.ADD_RTO_CASE),
    title: ["Services", "RTO Cases", "Add RTO Case"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.EDIT_RTO_CASE),
    title: ["Services", "RTO Cases", "Edit RTO Case"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.VIEW_RTO_CASE),
    title: ["Services", "RTO Cases", "View RTO Case"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.SERVICE_HISTORY.SERVICE_LIST),
    title: ["Services", "Service History"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.SERVICE_HISTORY.NEW_SERVICE),
    title: ["Services", "Service History", "Add New Service"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.SERVICE_HISTORY.VIEW_SERVICE),
    title: ["Services", "Service History", "Service Details"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.SERVICE_HISTORY.EDIT_SERVICE),
    title: ["Services", "Service History", "Service Details"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.CHALLAN),
    title: ["Services", "Challan"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.ADD_CHALLAN),
    title: ["Services", "Challan", "Edit New-Request Challan"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.NEW_CHALLAN_REQUEST),
    title: ["Services", "Challan", "Add New-Request Challan"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.VIEW_CHALLAN),
    title: ["Services", "Challan", "View Challan"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.EDIT_CHALLAN),
    title: ["Services", "Challan", "Edit Challan"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.TRANSACTIONS.ROUTE),
    title: ["Financials"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.TRANSACTIONS.TRANSACTION_LIST),
    title: ["Financials", "Transactions List"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.TRANSACTIONS.BANK_TRANSACTION),
    title: ["Financials", "Bank Transactions"],
  },

  {
    route: getAbsolute(MAIN_ROUTES.TRANSACTIONS.ADD_BANK_TRANSACTION),
    title: ["Financials", "Add Bank Transaction"],
  },

  {
    route: getAbsolute(MAIN_ROUTES.TRANSACTIONS.OUTSTANDING_TRANSACTION),
    title: ["Financials", "Outstanding"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.TRANSACTIONS.SERVICE_TRANSACTION),
    title: ["Financials", "Service Transactions"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.INVENTORY.ROUTE),
    title: ["Inventory"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.INVENTORY.INVENTORY_LIST),
    title: ["Inventory", "Inventory List"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.INVENTORY.NEW_INVENTORY),
    title: ["Inventory", "Inventory List", "Add New"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.LEAD.ROUTE),
    title: ["Lead"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.LEAD.LEAD_LIST),
    title: ["Lead", "Buyer Csv List"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.FINANCIALSERVICES.ROUTE),
    title: ["Financial Services"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.FINANCIALSERVICES.LOANS_LIST),
    title: ["Financial Services", "Loans"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.FINANCIALSERVICES.ADD_LOAN),
    title: ["Financial Services", "Loans", "Add New Loan"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.FINANCIALSERVICES.EDIT_LOAN),
    title: ["Financial Services", "Loans", "Edit Loan"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.FINANCIALSERVICES.VIEW_LOAN),
    title: ["Financial Services", "Loans", "View Loan"],
  },
  //
  {
    route: getAbsolute(MAIN_ROUTES.FINANCIALSERVICES.INSURANCE_LIST),
    title: ["Financial Services", "Insurance"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.FINANCIALSERVICES.ADD_INSURANCE),
    title: ["Financial Services", "Insurance", "Add New Insurance"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.FINANCIALSERVICES.EDIT_INSURANCE),
    title: ["Financial Services", "Insurance", "Edit Insurance"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.FINANCIALSERVICES.VIEW_INSURANCE),
    title: ["Financial Services", "Insurance", "View Insurance"],
  },
  {
    route: MAIN_ROUTES.PRIVACY_POLICY,
    title: ["Privacy Policy"],
  },
  {
    route: MAIN_ROUTES.TERMS_CONDITIONS,
    title: ["Terms & Conditions"],
  },
  {
    route: MAIN_ROUTES.LOAN_LENDER,
    title: ["Loan Lender Policy"],
  },
  {
    route: MAIN_ROUTES.INSURANCE_LENDER,
    title: ["Insurance Lender Policy"],
  },
  //
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.PHOTO_SHOOT),
    title: ["Services", "Phototshoot"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.ADD_PHOTO_SHOOT),
    title: ["Services", "Phototshoot", "Add New Request"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.VIEW_PHOTO_SHOOT),
    title: ["Services", "Phototshoot", "View Request"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.EDIT_PHOTO_SHOOT),
    title: ["Services", "Phototshoot", "Edit Request"],
  },

  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.CREATIVE_MAKING),
    title: ["Services", "Creative Designing"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.ADD_CREATIVE_MAKING),
    title: ["Services", "Creative Designing", "Add New Creative"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.VIEW_CREATIVE_MAKING),
    title: ["Services", "Creative Designing", "View Creative"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.EDIT_CREATIVE_MAKING),
    title: ["Services", "Creative Designing", "Edit Creative"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.VIEW_VAHAN_CHECK),
    title: ["Services", "Vahan Check", "View Vahan"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.ADD_VAHAN_CHECK),
    title: ["Services", "Vahan Check", "Vahan Check Service"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.VAHAN_CHECK),
    title: ["Services", "Vahan Check"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.SOCIAL_MEDIA),
    title: ["Services", "Social Media"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.ADD_SOCIAL_MEDIA),
    title: ["Services", "Social Media", "Add New Request"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.VIEW_SOCIAL_MEDIA),
    title: ["Services", "Social Media", "View Request"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.EDIT_SOCIAL_MEDIA),
    title: ["Services", "Social Media", "Edit Request"],
  },

  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.DIGITAL_MARKETING),
    title: ["Services", "Digital Marketing"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.ADD_DIGITAL_MARKETING),
    title: ["Services", "Digital Marketing", "Add New Request"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.VIEW_DIGITAL_MARKETING),
    title: ["Services", "Digital Marketing", "View Request"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.EDIT_DIGITAL_MARKETING),
    title: ["Services", "Digital Marketing", "Edit Request"],
  },

  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.WEBSITE_DEVELOPMENT),
    title: ["Services", "Website Development"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.ADD_WEBSITE_DEVELOPMENT),
    title: ["Services", "Website Development", "Add New Request"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.VIEW_WEBSITE_DEVELOPMENT),
    title: ["Services", "Website Development", "View Request"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.EDIT_WEBSITE_DEVELOPMENT),
    title: ["Services", "Website Development", "Edit Request"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.LEAD.CUSTOMER_LEAD),
    title: ["Customer Lead", "Customer Lead List"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.LEAD.CUSTOMER_PREFERENCES) + "/:id",
    title: ["Customer Lead", "Customer Lead List", "Customer Preferences"],
  },

  //
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.D2D_AUCTION),
    title: ["Services", "D2D Auction"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.VIEW_D2D_AUCTION),
    title: ["Services", "D2D Auction", "View Auction"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.EDIT_D2D_AUCTION),
    title: ["Services", "D2D Auction", "Edit Auction"],
  },

  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.D2D_REQUIREMENT),
    title: ["Services", "D2D Requirement"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.VIEW_D2D_REQUIREMENT),
    title: ["Services", "D2D Requirement", "View D2D Requirement"],
  },
  {
    route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.EDIT_D2D_REQUIREMENT),
    title: ["Services", "D2D Requirement", "Edit D2D Requirement"],
  },
];

export const getHeadings = (route) => {
  const object = Headings.find((heading) => {
    if (heading?.route?.includes(":id")) {
      const routePattern = heading.route.replace(":id", "\\d+");
      return new RegExp(`^${routePattern}$`).test(route);
    }
    return heading.route === route;
  });
  return object ? object.title : ["Untitled"];
};

export const GetHeadings = (route) => {
  // const { basicDetails } = useSelector((state) => state.loanRequest);

  const object = Headings?.filter((heading) => heading?.route === route)[0];

  // if(object?.route?.includes("/financial-services/loans-list/") && basicDetails?.customer_name){
  //   const baseTitle = [...object.title]

  //   return object.title =  [...baseTitle.slice(0,3), baseTitle[2] = `${basicDetails?.customer_name} > ${basicDetails?.dealer_id}`]
  //   }

  if (object != null) return object?.title;
  else return ["Untitled"];
};

export const DealersDetails = ({ dealership_name, dealercode }) => {
  // console.log(`you dealer name ${dealership_name} and ${dealercode}`);

  if (dealership_name && dealercode) {
    return `${dealership_name} - ${dealercode}`;
  }
  return "Details not available";
};
