import {
  ADD_AUCTION,
  GET_AUCTION_DETAIL,
  GET_AUCTION_LIST,
  GET_BID_CHAT,
  GET_BID_OFFER_LIST,
  GET_REQUIREMENT_LIST,
  SEND_BID_CHAT,
  UPDATE_AUCTION,
  GET_REQUIREMENT_DETAIL,
  ADD_REQUIREMENT,
  UPDATE_REQUIREMENT,
} from "../types";

const initialState = {
  auctionList: [],
  bidOfferList: [],
  requirementList: [],
  bidChat: [],
  auctionDetail: {},
  requirementDetail: {},
  total: 0,
  bidTotal: 0,
  bidChatTotal: 0,
  requirementTotal: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_AUCTION:
      return {
        ...state,
        auctionList: [payload, ...state.auctionList],
      };

    case UPDATE_AUCTION:
      return {
        ...state,
        auctionList: state.auctionList.map((d2d) =>
          d2d.id === payload.id ? { ...d2d, ...payload } : d2d
        ),
        // auctionDetail: payload,
      };

    case ADD_REQUIREMENT:
      return {
        ...state,
        requirementList: [payload, ...state.requirementList],
      };

    case UPDATE_REQUIREMENT:
      return {
        ...state,
        requirementList: state?.requirementList?.map((d2d) =>
          d2d.id === payload.id ? { ...d2d, ...payload } : d2d
        ),
        // auctionDetail: payload,
      };

    case GET_AUCTION_LIST:
      return {
        ...state,
        auctionList: payload?.data,
        total: payload?.total,
      };

    case GET_REQUIREMENT_LIST:
      return {
        ...state,
        requirementList: payload?.data,
        requirementTotal: payload?.total,
      };

    case GET_AUCTION_DETAIL:
      return {
        ...state,
        auctionDetail: payload?.data,
      };

    case GET_REQUIREMENT_DETAIL:
      return {
        ...state,
        requirementDetail: payload?.data,
      };

    case GET_BID_OFFER_LIST:
      return {
        ...state,
        bidOfferList: payload?.data,
        bidTotal: payload?.total,
      };

    case GET_BID_CHAT:
      return {
        ...state,
        bidChat: payload?.data,
        bidChatTotal: payload?.total,
      };

    case SEND_BID_CHAT:
      return {
        ...state,
        bidChat: [payload, ...state.bidChat],
      };

    default:
      return state;
  }
}
